import React from "react";
import styled from "@emotion/styled";

import {
  FacebookShareButton,
  FacebookIcon,
  FacebookShareCount,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  ViberShareButton,
  ViberIcon,
} from "react-share";

const ShareButtons = ({ title, url, twitterHandle }) => {
  return (
    <ButtonContainer>
      <ShareText>Share the love</ShareText>
      <FacebookShareButton url={url} title={title}>
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title} via={twitterHandle}>
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>

      <LinkedinShareButton url={url} title={title}>
        <LinkedinIcon size={40} round={true} />
      </LinkedinShareButton>

      <RedditShareButton url={url} title={title}>
        <RedditIcon size={40} round={true} />
      </RedditShareButton>

      <ViberShareButton url={url} title={title}>
        <ViberIcon size={40} round={true} />
      </ViberShareButton>
    </ButtonContainer>
  );
};
export default ShareButtons;

const ButtonContainer = styled.div`
  button:not(:last-child) {
    margin-right: 15px;
  }
  margin-top: 20px;
  margin-bottom: 5rem;
  text-align: center;
`;

const ShareText = styled.div`
  color: ${(p) => p.theme.colors.primary};
  margin-bottom: 10px;
  font-size: 14px;
  text-transform: uppercase;
`;
